//
// Dropzone
//

.dropzone {
  display: flex;
  padding: 32px 0px 31px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 1px dashed var(----black, #000);
  background: var(----white, #fff);
  height: 100%;
  /* Body/regular */
  width: 683px;

  .dz-message {
    color: var(----gray-medium, #666);
    text-align: center;
    /* Body/regular */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
