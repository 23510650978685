.video_controls {
    display: flex;
    justify-content: space-between;
    background: #2970f1c7;
    align-items: center;
    height: 52px;
    bottom: 88px;
    .left {
      width: 116px;
      span {
        transform: translateY(2px);
        font-size: 13px;
      }
    }
    .video_container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .play_btn {
        background: none;
        border: none;
      }
      .input_container {
        display: flex;
        width: 100%;
        transform: translateY(5px);
        .width100 {
          width: 100%;
        }
      }
    }
  }
  .video_controls {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .controls_bottom {
    transform: translateY(-9px);
    transition: all 0.3s ease-out;
    opacity: 0;
  }
  #displa-none {
    display: none;
    width: 688px;
    height: 393px;
    border-radius: 10px;
    @media screen and (max-width: 700px) {
      width: 100%;
      margin: 0 auto;
      aspect-ratio: 393/688;
    }
  }
  #img_hover {
    width: 688px;
    height: 393px;
    border-radius: 10px;
    @media screen and (max-width: 700px) {
      width: 100%;
      margin: 0 auto;
      aspect-ratio: 393/688;
    }
  }
  .controls_top {
    transform: translateY(-61px);
    opacity: 1;
    transition: all 0.3s ease-out;
  }
  .video {
    height: 400px;
    width: 688px;
    @media screen and (max-width: 700px) {
      width: 95%;
      margin: 0 auto;
      aspect-ratio: 400/688;
    }
  
    video {
      width: 688px;
      height: 393px;
      border-radius: 10px;
      @media screen and (max-width: 700px) {
          width: 100%;
          margin: 0 auto;
          aspect-ratio: 393/688;
        }
    }
  }
  .white {
    width: 100%;
    background-color: white;
    height: 30px;
    transform: translateY(-62px);
  }
  