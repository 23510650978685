.Navbar {
  //   margin-bottom: 10px;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 10;
  width: 100vw;
  background: var(----white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  &_Hamburger {
    &_Image {
      margin-right: 15px;
    }
  }
  &_Content {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 12px;

    display: flex;
    // background: red;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1100px) {
      padding: 12px;
    }
  }
  &_Left {
    width: auto;
    &_Heading {
      color: #000;
      //   text-align: center;
      /* Body/bold */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0 !important;
    }
    &_Paragraph {
      color: #000;
      //   text-align: center;
      /* Label/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0 !important;
    }
    &_Logo {
      display: flex;
      align-items: center;
      gap: 8px;
      width: auto;
      height: 88px;
      //   background: #d9d9d9;
    }
    &_Data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &_Right {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    gap: 44px;
    @media screen and (max-width: 850px) {
      gap: 10px;
    }

    &_Button {
      display: flex;
      align-items: center;
      width: auto;
      gap: 16px;
      &_Primary {
        display: flex;
        width: 98px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid var(----primary, #1e90ff);
        color: var(----primary, #1e90ff);
        text-align: center;
        /* Body/medium */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        &:hover {
          cursor: pointer;
        }
      }
      &_Secondary {
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: var(----primary, #1e90ff);
        color: var(----white, #fff);
        text-align: center;
        /* Body/medium */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    li {
      color: var(----gray-medium, #666);
      text-align: center;
      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &_Box {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #302d2d;
    }
  }
}

.Classes {
  width: 100%;
  background: white;
  margin-top: 32px;
  margin-bottom: 60px;
  &_Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 1120px;
    margin: 0 auto;
    &_Flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &_Heading {
      color: #000;
      text-align: center;
      /* H4/bold */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
    &_Content {
      display: flex;
      width: 100%;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 36px;
      &_Single {
        display: flex;
        width: 349.333px;
        padding: 8px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #ccc;
        background: #fff;

        @media only screen and (max-width: 600px) {
          margin: 12px auto;
        }
        &_paragraph {
          color: var(----gray-medium, #666);
          /* Label/regular */
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin: 0;
        }
        &_heading {
          color: #000;
          margin: 0;
          /* H5/bold */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
        &_paragraph1 {
          color: var(----gray-medium, #666);
          /* Body/regular */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        &_Button {
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          color: var(----white, #fff);
          text-align: center;
          /* Body/medium */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          border-radius: 8px;
          background: var(----primary, #1e90ff);
          border: none;
          outline: none;
        }
      }
    }
  }
}

.Footer {
  background: #000e22;
  width: 100vw;
  height: 534px;

  //   max-width: 1120px;
  &_Content {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;

    justify-content: space-between;
    padding-top: 60px;
    @media only screen and (max-width: 1120px) {
      padding: 30px;
    }
    @media only screen and (max-width: 800px) {
      flex-direction: column;
      padding-top: 30px;
      justify-content: flex-start;
    }
  }
  &_Left {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 36px;
    @media only screen and (max-width: 800px) {
      gap: 10px;
    }
    &_Heading {
      color: #fff;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0 !important;
    }
    &_Paragraph {
      color: #fff;
      //   text-align: center;
      /* Label/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0 !important;
    }
    &_Logo {
      display: flex;
      align-items: center;
      gap: 8px;
      width: auto;
      height: 88px;
    }
    &_Data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &_Bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      &_Heading {
        color: var(----white, #fff);
        /* H4/regular */
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
      &_Paragraph {
        color: var(----white, #fff);
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  &_Right {
    display: flex;
    align-items: flex-start;
    gap: 100px;
    width: 350px;

    &_Box {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      &_Heading {
        color: var(----white, #fff);

        /* H4/bold */
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
      &_List {
        text-decoration: none;
        list-style: none;
        color: var(----white, #fff);
        display: flex;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        li {
          color: var(----white, #fff);
        }
      }
    }
  }
}
@import '~video-react/styles/scss/video-react';

.TestSet {
  width: 100%;
  background: white;
  margin-top: 32px;
  margin-bottom: 60px;
  &_Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 1400px;
    margin: 0 auto;
    &_Heading {
      color: #000;
      text-align: center;
      /* H4/bold */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
    &_Content {
      display: flex;
      width: 100%;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 36px;
      &_Single {
        display: flex;
        width: 349.333px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid #ccc;
        padding: 8px 16px;
        gap: 2px;
        align-self: stretch;
        background: red;
        &:hover {
          cursor: pointer;
        }
        &_Image {
          height: auto;
          width: 300px;
          align-self: stretch;
          //   margin: 0 auto;
        }
        &_paragraph {
          color: var(----gray-medium, #666);
          /* Body/regular */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
        }

        &_Heading {
          color: var(----gray-dark, #333);
          /* H5/bold */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin: 0;
        }
        &_Button {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          color: var(----white, #fff);
          text-align: center;
          /* Body/medium */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          width: auto;
          line-height: 24px;
          border-radius: 8px;
          background: var(----primary, #1e90ff);
          border: none;
          outline: none;
        }
      }
    }
  }
}

.Landing1 {
  width: 100%;
  background: #f3f6f9;
  padding-top: 32px;
  margin-bottom: 60px;
  &_Content {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    gap: 36px;
    // align-items: center;
    position: relative;
    @media only screen and (max-width: 960px) {
      flex-direction: column;
    }
  }
  &_Left {
    display: flex;
    max-width: 349px;

    flex-direction: column;
    align-items: flex-start;
    // position: sticky;
    gap: 16px;
    position: sticky;
    top: 100px;
    left: 0;
    @media only screen and (max-width: 960px) {
      flex-direction: row;
      justify-content: space-between;
    }
    &_Bottom {
      display: flex;
      max-width: 349px;
      padding: 16px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      background: var(----white, #fff);
      @media only screen and (max-width: 960px) {
        display: none;
      }
      &_Box {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        width: 100%;
        height:auto;
        flex-wrap: wrap;
        align-self: stretch;

        &_Box {
          display: flex;
          padding: 8px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: var(----gray-light, #ededed);
          max-width: 42px;
          height: 40px;
          // margin-right: 16px;
          margin: 5px;
        }
      }
      &_Heading {
        color: #000;
        /* Body/bold */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
    &_Top {
      display: flex;
      width: 349px;
      padding: 24px 32px;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      background: var(----white, #fff);
      &_Time {
        margin: 0;
      }
      &_Timer {
        color: #000;
        /* H4/bold */
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
      &_Paragraph {
        color: #000;
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
      }
    }
    // padding: 24px 32px;
    // justify-content: space-between;
    // align-items: center;
  }
  &_Right {
    // flex: 1;
    position: relative;
    width: 100%;
    min-width: 350px;

    &_Image {
      position: absolute;
      top: 0;
      right: 0;
    }
    &_Heading {
      color: #000;

      /* H4/bold */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
    &_Button {
      width: 100%;
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &_Primary {
        display: flex;
        max-width: 161px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: var(----primary, #1e90ff);
        color: var(----white, #fff);

        text-align: center;
        /* Body/medium */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        border: none;
        outline: none;
      }
    }
  }
}
.Question {
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //   gap: 24px;
  border-radius: 8px;
  background: #fff;

  /* s3 */
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
  &_Heading {
    color: var(----gray-dark, #333);
    /* H4/medium */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
  &_Paragraph {
    color: var(----gray-medium, #666);
    /* Body/regular */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.Chapter {
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  &_Content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    &_Single {
      display: flex;
      width: 100%;
      min-width: 300px;

      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: #fff;
      flex-direction: column;
      /* s1 */
      box-shadow: 0px 0px 24px 2px rgba(249, 249, 249, 0.05),
        0px 0px 32px 8px rgba(0, 0, 0, 0.1);
      // margin: 15px;
      margin: 15px auto;
      &_Box {
        border-top-left-radius: 10px; /* Apply border radius to the top-left corner */
        border-top-right-radius: 10px;
        padding: 20px;
      }
      &:hover {
        cursor: pointer;
      }

      &_Paragraph {
        color: var(----gray-dark, #333);
        /* Body/bold */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &_Heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    &_Heading {
      color: #000;
      /* H4/bold */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
  }
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

.Modal {
  width: 100%;
  position: relative;
  display: flex;

  &_Body {
    display: flex;
    width: 100%;
    flex-direction: column;

    // justify-content: center;
    // align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 8px;
    background: inherit;

    /* s1 */

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    // align-items: space-between;
    // justify-content: space-between;
    // @media only screen and (max-width: 600px) {
    //   // padding: 5px;
    // }
    // column-gap: 16px;
    &_Single {
      display: inline-flex;

      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 6px 20px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }

      &_Heading {
        color: var(----gray-dark, #333);
        /* Body/bold */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      &_Paragraph {
        color: var(----secondary, #00bfff);
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

.Billing {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f3f6f9;
  width: 100vw;
  margin-top: 15px;
  &_Content {
    display: flex;
    max-width: 1120px;
    width: 100%;
    padding: 36px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      padding: 10px;
    }
  }
  &_Heading {
    color: var(----gray-dark, #333);
    /* H4/bold */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }
  &_Box {
    display: flex;
    max-width: 1120px;
    width: 100%;
    padding: 36px;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
    background: #fff;
    @media only screen and (max-width: 600px) {
      padding: 10px;
    }

    &_Paragraph {
      color: var(----gray-medium, #666);
      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &_Input {
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      gap: 234px;
      width: 100%;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(----gray, #e6e6e6);
      background: var(----white, #fff);
      color: var(----gray-medium, #666);
      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &_Amount {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      &_Text {
        display: flex;
        padding-bottom: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        border-bottom: 1px solid var(----gray, #e6e6e6);
      }
      &_Text1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        &_Paragraph {
          color: var(----gray-medium, #666);
          /* Body/regular */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
        }
        &_Strong {
          color: var(----gray-dark, #333);
          /* Body/bold */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
    &_PromoCode {
      display: flex;
      align-items: flex-start;
      border-radius: 8px;
      width: 100%;
      background: var(----white, #fff);
      /* s4 */
      box-shadow: 0px 0px 12px 0.5px rgba(0, 0, 0, 0.1);

      &_Input {
        display: flex;
        // width: 100%;
        flex: 1;
        padding: 12px;
        align-items: center;
        gap: 10px;
        min-width: 130px;
        color: var(----gray-medium, #666);
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border: none;
        outline: none;
      }
      &_Button {
        display: flex;
        min-width: 32px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        outline: none;
        border-radius: 0px 8px 8px 0px;
        background: var(----primary, #1e90ff);
        color: var(----white, #fff);
        text-align: center;
        /* Body/medium */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  &_Payment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    &_Paragraph {
      color: var(----gray-dark, #333);
      /* Body/bold */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    &_Box {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      background: var(----white, #fff);
      /* s3 */
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
      &_Image {
        width: 157px;
        height: 69.245px;
      }
    }
  }
}

.Submission {
  background: #f3f6f9;
  width: 100vw;
  &_Content {
    display: flex;
    max-width: 1120px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin: 0 auto;
    margin-top: 15px;
  }
  &_Heading {
    color: var(----gray-dark, #333);
    text-align: center;
    /* H4/bold */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }
  &_Box {
    display: flex;
    max-width: 1120px;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: var(----white, #fff);
    &_Top {
      display: flex;
      width: 100%;
      padding: 0 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      &_Box {
        width: 100%;
      }
      &_InputBox {
        display: flex;
        height: 40px;
        padding: 8px;
        align-items: flex-start;
        gap: 4px;
        border-radius: 4px;
        border: 1px solid var(----gray, #e6e6e6);
        background: var(----white, #fff);
        flex: 1 0 0;
        // justify-content:/;
        &_Image {
          width: 24px;
          height: 24px;
        }
        &_Input {
          width: 412px;
          height: 100%;
          color: var(----gray-medium, #666);
          /* Body/regular */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          border: none;
          outline: none;
          line-height: 24px;
        }
      }
      &_Paragraph {
        color: var(----gray-medium, #666);
        text-align: center;
        /* Body/bold */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
    &_Bottom {
      width: 100%;
      padding: 0 16px;
    }
  }
}

.Table {
  width: 100%;
  &_Head {
    display: flex;
    padding: 16px 8px;
    align-items: center;
    gap: 36px;
    align-self: stretch;
    background: var(----gray-light, #ededed);
    width: 100%;
    &_Text {
      color: var(----gray-dark, #333);
      /* Body/bold */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
  &_Body {
    &_Text {
      color: var(----gray-medium, #666);
      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &_Text1 {
      color: var(----gray-medium, #666);
      /* Body/bold */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    &_Danger {
      display: flex;
      width: 80px;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #e52222 !important;
      height: 26px;
      color: var(----white, #fff);
      text-align: center;
      /* Label/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      border: none;
      outline: none;
    }
    &_Primary {
      display: flex;
      width: 161px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(----primary, #1e90ff);
    }
    &_Success {
      display: flex;
      width: 80px;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #4e8c32 !important;
      height: 26px;
      color: var(----white, #fff);
      text-align: center;
      /* Label/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      border: none;
      outline: none;
    }
  }
}

.Register {
  max-width: 1120px;
  margin: 0 auto;
  margin-top: 32px;
  display: flex;
  height: auto;
  margin: 0 auto;
  justify-content: center;
  padding: 30px 0;
  &_Left {
    max-width: 541px;
    background: var(----primary, #1e90ff);
    width: 100%;
    padding: 30px 0;
    flex-shrink: 0;
    border-radius: 8px 0px 0px 8px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
    @media screen and (max-width: 830px) {
      display: none;
    }
    div {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  &_Right {
    display: flex;
    max-width: 579px;
    width: 100%;

    padding: 16px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 0px 8px 8px 0px;
    background: #fff;
    &_Paragraph {
      color: var(----gray-medium, #666);
      text-align: center;
      /* Label/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    &_Login1 {
      color: var(----gray-dark, #333);

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &_Login {
      color: var(----gray-dark, #333);

      width: 100%;

      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &_Link {
        color: var(----primary, #1e90ff);
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-left: auto;
      }
    }
    &_Box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      &_Button {
        // margin-top: 24px;
        width: 100%;
        &_Button {
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 100%;
          border: none;
          outline: none;
          align-self: stretch;
          border-radius: 8px;
          background: var(----primary, #1e90ff);
          color: #fff;

          text-align: center;
          /* Body/medium */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
      &_InputBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        width: 100%;
        &_Heading {
          color: var(----gray-dark, #333);
          /* Body/regular */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        &_Input {
          display: flex;
          padding: 12px;
          align-items: center;
          gap: 10px;
          width: 100%;
          color: var(----gray-medium, #666);
          /* Body/light */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid var(----gray, #e6e6e6);
        }
      }
    }
    &_Top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    }
    &_Heading {
      color: var(----gray-dark, #333);
      /* Body/bold */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }
    &_Paragraph {
      color: var(----primary, #1e90ff);
      /* Label/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
    }
  }
}

/* Container for layout */
.AdminDashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set to viewport height for full-screen layout */
  &_Section {
    height: calc(100vh - 105px);
    display: flex;
    // background: red;
  }
  &_Main {
    flex: 1;
    height: 100%;
    // background: green;
    padding: 67px;
    &_Box {
      display: flex;
      max-width: 1056px;
      // width:100%;
      padding: 16px 32px 32px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      background: var(----white, #fff);
      /* s3 */
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);

      &_Hello {
        height: auto;
        width: auto;
        display: flex;
        flex-direction: column;
        width: 683px;
      }
      &_Box {
        display: flex;
        align-items: center;
        height: auto;
      }
      &_DropZone {
        display: flex;
        padding: 32px 0px 31px 0px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border: 1px dashed var(----black, #000);
        background: var(----white, #fff);
        height: 100%;
        /* Body/regular */
        width: 683px;

        &_Text {
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          color: var(----gray-medium, #666);
          text-align: center;
          line-height: 24px;
        }
      }
      &_Right {
        display: flex;
        width: 273px;
        height: 100%;
        height: auto;
        padding: 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        gap: 16px;
        align-self: stretch;
        &_Button {
          margin-top: auto;
          display: flex;
          padding: 12px 24px;
          // justify-content: flex-end;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: var(----primary, #1e90ff);
          border: none;
          outline: none;
          color: var(----white, #fff);

          text-align: center;
          /* Body/medium */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
        &_Textarea {
          display: flex;
          padding: 16px 24px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid #e6e6e6;
          background: #fff;
          width: 100%;
          color: var(----gray-medium, #666);
          /* Body/regular */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        &_Select {
          display: flex;
          padding: 16px 24px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid #e6e6e6;
          background: #fff;
          width: 100%;
          color: var(----gray-medium, #666);
          /* Body/regular */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      &_Heading {
        color: var(----black, #000);
        /* H4/bold */
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
      &_Paragraph {
        color: var(----gray-medium, #666);
        text-align: center;
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    &_Paragraph {
      color: var(----black, #000);
      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  &_Nav {
    width: 264px;
    position: sticky;
    top: 0; /* Stick to the top of the viewport */
    left: 0; /* Stick to the left of the viewport */
    display: flex;
    height: 100%;
    padding: 37px 60px 37px 60px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background: var(----white, #fff);
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.05);
    &_Link {
      display: flex;
      align-items: center;
      &_Paragraph {
        color: var(----gray-medium, #666);
        text-align: center;
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    &_Paragraph {
      color: var(--Color-gray-300, #999);
      /* Body/bold */
      // font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
  &_Main {
    flex: 1; /* Allow main content to fill remaining space */
    padding: 20px;
  }
}

/* Header (Topbar) */
header {
  /* Ensure header stays on top during overlap */
}

/* Sidebar (Nav) */
nav {
  /* Ensure sidebar stays on top during overlap */
}

nav a {
  text-decoration: none;
  padding: 10px;
  color: black;
}

.Landing {
  padding: 60px 10px;
  background: #f3f6f9;
  position: relative;
  width: 100%;
  height: 100%;
  &_Content {
    display: flex;
    gap: 35px;
    margin: 0 auto;
    max-width: 1120px;
    height: 100%;
    position: relative;
    @media only screen and (max-width: 900px) {
      // padding: 10px;
      flex-direction: column;
      overflow: hidden;
    }

    &_Left {
      height: 100%;
      display: flex;
      flex-direction: column;

      justify-content: center;
      gap: 24px;
      max-width: 542px;
      height: 500px;
      position: sticky;
      top: 0;

      @media screen and (min-width: 768px) {
        flex: 1 1 50%;
      }
      &_Heading {
        color: var(----black, #000);
        /* H1/bold */
        font-family: Roboto;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
      }
      &_Paragraph {
        color: var(----gray-medium, #666);
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      &_Button {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        &_Primary {
          display: flex;
          // width: 161px;
          border: none;
          outline: none;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: var(----primary, #1e90ff);
          color: var(----white, #fff);
          text-align: center;
          /* Body/medium */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
        &_Secondary {
          border: none;
          outline: none;
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          border: 1px solid var(----primary, #1e90ff);
          color: var(----primary, #1e90ff);
          text-align: center;
          /* Body/medium */
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
    &_Right {
      display: flex;
      flex: 1 1 100%;
      position: relative;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 36px;
      // max-height: 500px;
      // overflow: hidden;
      width: 100%;
      @media screen and (max-width: 968px) {
        flex: 1 100%;
        justify-content: center;
      }
      &_Image {
        position: absolute;
        bottom: 0;
        left: 0;
        @media only screen and (max-width: 968px) {
          display: none;
        }
        // z-index: -1;
      }
      &_Video {
        z-index: 2;
      }
      &_Box1 {
        display: flex;
        flex-direction: column;
        gap: 17px;
        width: 100%;
        @media screen and (max-width: 968px) {
          display: none;
        }
      }
      &_Box2 {
        display: flex;
        flex-direction: column;
        gap: 17px;
        width: 100%;
      }
      @media screen and (min-width: 768px) {
        flex: 1 1 50%;
      }
      @media screen and (max-width: 568px) {
        display: none;
      }
    }
  }
}

.GoFar {
  padding: 60px 10px;
  background: var(----white, #fff);
  &_Content {
    display: flex;
    max-width: 1440px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin: 0 auto;
    &_Single {
      display: flex;
      max-width: 253px;
      padding: 16px;
      justify-content: center;
      align-items: center;

      border-radius: 8px;
      background: var(----white, #fff);

      /* s1 */
      box-shadow: 0px 0px 24px 2px rgba(249, 249, 249, 0.05),
        0px 0px 32px 8px rgba(0, 0, 0, 0.1);
      &_Paragraph {
        color: var(----gray-dark, #333);
        text-align: center;
        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
      }
    }
    &_Box {
      display: flex;
      align-items: center;
      gap: 36px;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &_Heading {
      color: var(----black, #000);

      text-align: center;
      /* H3/bold */
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
    &_Span {
      color: var(----primary, #1e90ff);
      /* H3/bold */
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
  }
}
.About {
  background: #f3f6f9;
  width: 100%;
  position: relative;
  &_Image {
    position: absolute;
    right: 0;
    bottom: 0;
    &_1 {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    // z-index: -1;
  }
  &_Content {
    max-width: 1120px;
    padding: 60px 10px;
    display: flex;
    align-items: center;
    gap: 36px;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }

    &_Right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      max-width: 465px;
      height: auto;
      width: 98%;
      // max-height: 325px;

      flex-shrink: 1;
      position: relative;
      img {
        z-index: 2;
        height: auto;
        width: 100%;
      }
      &_Rectangle {
        width: 157px;
        height: 127px;
        background: var(----accent, #153a6f);
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 1;
      }
    }
    &_Box1 {
      max-width: 465px;
      width: 100%;
      height: auto;
      background: #d9d9d9;
      img {
        width: 100%;
      }
    }
    &_Box2 {
      max-width: 157px;
      height: auto;

      background: #d9d9d9;
      img {
        width: 100%;
      }
    }
    &_Left {
      display: flex;
      max-width: 446px;
      height: 325px;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      gap: 16px;
      text-wrap: wrap;
      width: 100%;
      &_Heading {
        color: var(----black, #000);

        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
      }
      &_Paragraph {
        color: var(----black, #000);

        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
.JoinToday {
  background: var(----blue-light, #e8f0ff);
  position: relative;
  &_Image {
    position: absolute;
    top: 0;
    left: 50px;
    // z-index:-1;
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
  &_Content {
    display: flex;
    max-width: 1440px;
    padding: 60px 160px;
    z-index: 2;
    @media screen and (max-width: 768px) {
      padding: 60px 10px;
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    &_Heading {
      color: var(----black, #000);

      text-align: center;
      /* H4/bold */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
    &_Span {
      color: var(----primary, #1e90ff);
      /* H4/bold */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
    &_Paragraph {
      color: var(----gray-medium, #666);
      text-align: center;
      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 12px !important;
      margin: 0;
    }
    &_Button {
      display: flex;
      width: 161px;
      padding: 12px 24px;
      justify-content: center;
      margin-top: 24px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(----primary, #1e90ff);
      color: var(----white, #fff);
      border: none;
      outline: none;

      text-align: center;
      /* Body/medium */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
.content-box-1 {
  max-width: 253px;
  width: 100%;
  background: #d9d9d9;
}
.content-box-2 {
  max-width: 253px;
  width: 100%;

  background: #d9d9d9;
}
.content-box-3 {
  max-width: 254px;
  width: 100%;

  background: #d9d9d9;
}
.content-box-4 {
  max-width: 253px;
  width: 100%;
  background: #d9d9d9;
}

.Slider {
  background: #f3f6f9;
  width: 100%;
  &_Content {
    display: flex;
    max-width: 1440px;
    padding: 60px 160px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin: 0 auto;
    &_Slider {
      display: flex;
      padding: 32px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(----gray, #e6e6e6);
      background: var(----white, #fff);
      &_Content {
        height: 256px;
        display: flex;
        align-items: center;
        gap: 36px;
        &_Right {
          height: 256px;
          width: 510px;
          background: var(----gray-dark, #333);
        }
        &_Left {
          display: flex;
          width: 510px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          &_Heading {
            color: var(----black, #000);
            /* H4/bold */
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
          }
          &_Paragraph {
            color: var(----gray-medium, #666);
            /* Body/regular */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
          &_Span {
            color: var(----primary, #1e90ff);
            /* Body/bold */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }
    }
    &_Paragraph {
      color: var(----primary, #1e90ff);

      /* Body/medium */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &_Heading {
      color: var(----black, #000);

      /* H3/bold */
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
  }
}

.Moments {
  background: var(----white, #fff);
  width: 100%;
  &_Content {
    display: flex;
    max-width: 1440px;
    padding: 60px 160px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin: 0 auto;
    @media screen and (max-width: 1068px) {
      padding: 60px 30px;
    }
    @media screen and (max-width: 768px) {
      padding: 60px 10px;
    }
    &_Paragraph {
      color: var(----primary, #1e90ff);
      /* Body/medium */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &_Heading {
      color: var(----black, #000);
      /* H3/bold */
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
    &_Box {
      display: flex;
      max-width: 1120px;
      align-items: flex-start;
      width: 100%;
      gap: 16px;
      flex-direction: column;

      &_firstrow {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 26px;
        justify-content: center;
        width: 100%;
        @media screen and (max-width: 1168px) {
          gap: 10px;
        }
        .firstrow1 {
          @media screen and (max-width: 768px) {
            display: none;
          }
          img {
            width: 100%;
          }
        }
        .firstrow2 {
          display: flex;
          flex-direction: column;
          gap: 8px;
          img {
            width: 100%;
            @media screen and (max-width: 868px) {
              display: none;
            }
          }
          iframe {
            width: 100%;
          }
        }
        .firstrow3 {
          cursor: pointer;
          @media screen and (max-width: 568px) {
            display: none;
          }
          a {
            img {
              width: 100%;
            }
          }
        }
      }
      &_secondRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 26px;
        width: 100%;
        @media screen and (max-width: 1168px) {
          gap: 10px;
        }
        @media screen and (max-width: 868px) {
          flex-direction: column;
          align-items: center;
        }
        div {
          max-width: 496px;
          width: 100%;
          iframe {
            width: 100%;
            @media screen and (max-width: 468px) {
              height: 200px;
            }
          }
        }
      }

      &_Left {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      &_Right {
        // width: 349px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
.Boost {
  background: #f3f6f9;
  &_Content {
    display: flex;
    max-width: 1440px;
    padding: 60px 160px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
      padding: 60px 10px;
    }
    &_Box {
      max-width: 1120px;
      width: 100%;
      border-radius: 8px;
      background: var(----blue-light, #e8f0ff);
      padding: 52px 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      position: relative;
      @media screen and (max-width: 1068px) {
        padding: 52px 10px;
      }
      &_Image {
        position: absolute;
        bottom: 0;
        right: 60px;
        width: 35%;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      &_Box {
        max-width: 463px;
        width: 60%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      &_Heading {
        color: var(----black, #000);
        /* H4/bold */
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
      &_Paragraph {
        color: var(----black, #000);

        /* Body/regular */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      &_Button {
        display: flex;
        width: 161px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: var(----primary, #1e90ff);
        color: var(----white, #fff);
        text-align: center;
        /* Body/medium */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        border: none;
        outline: none;
        border: none;
        outline: none;
      }
    }
  }
}

.AddUser {
  &_Heading {
    color: var(----gray-dark, #333);
    /* Body/bold */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  &_Button {
    margin: 0 auto;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: var(----primary, #1e90ff);
    color: var(----white, #fff);
    text-align: center;
    /* Body/medium */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border: none;
    outline: none;
  }
  &_Input1 {
    display: flex;
    align-items: center;

    &_Input {
      height: 50px;
      width: 50px;
      margin-left: 10px;
    }
  }
  &_Input {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    &_Name {
      color: var(----gray-dark, #333);
      /* Body/regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &_Input {
      width: 100%;
      display: flex;
      padding: 12px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(----gray, #e6e6e6);
    }
  }
}

.AdminUsers {
  display: flex;
  width: 100%;
  align-items: center;
  &_Input {
    display: flex;
    width: 385px;
    padding: 8px;
    align-items: flex-start;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid var(----gray, #e6e6e6);
    background: var(----white, #fff);
    outline: none;
  }
  &_Button {
    display: flex;
    padding: 12px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(----primary, #1e90ff);
    color: var(----white, #fff);
    border: none;
    outline: none;

    /* Body/medium */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-right: auto;
  }
}

.NotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  max-width: 1120px;
  margin: 0 auto;
  &_Image {
    @media screen and (max-width: 768px) {
      width: 98%;
      height: auto;
    }
  }
  &_Heading {
    color: var(----black, #000);
    /* H4/bold */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }
  &_Button {
    display: flex;
    width: auto;
    margin: 0 auto;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(----primary, #1e90ff);
    color: var(----white, #fff);
    text-align: center;
    /* Body/medium */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border: none;
    outline: none;
  }
}
.Blogs_Paragraph {
  font-family: Roboto;
  color: var(----black, #000);
  p {
    color: var(----black, #000);
    /* H4/bold */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;

    line-height: 20px;
  }
}
